<template>
  <div class="container"></div>
</template>

<script>
export default {
  name: 'Rent',
  mounted() {
    this.$router.push('/rent/apartments')
  }
}
</script>
